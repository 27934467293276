import React, { useState, useContext } from "react";
import { DataContext } from "./common/DataContext";
// import './Checkout.css';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const Checkout = ({
  shoppingCart,
  subtotal,
  salesTax,
  total,
  shippingMethod,
  shippingPrice,
  streetAddress,
  name,
  city,
  unit,
  state,
  country,
  postalCode,
  email,
}) => {
  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
  const [currency, setCurrency] = useState(options.currency);
  var entry = null;

  const { createOrder,mobile } = useContext(DataContext);

  const onCurrencyChange = ({ target: { value } }) => {
    setCurrency(value);
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: value,
      },
    });
  };

  const onCreateOrder = (data, actions) => {
    console.log("Shopping Cart:", shoppingCart);
    console.log("Subtotal:", subtotal);
    console.log("Total:", total);
    console.log("Shipping Method:", shippingMethod);
    console.log("Shipping Price:", shippingPrice);
    console.log("Sales Tax:", salesTax.toFixed(2));
    const calculatedTotal = (subtotal + shippingPrice+salesTax).toFixed(2);
    console.log("Calculated Total:", calculatedTotal);

    // Validate that total matches the calculated total
    if (calculatedTotal !== total.toFixed(2)) {
      console.log(calculatedTotal, total.toFixed(2))
      console.error("Total amount mismatch!");
      return;
    }
    // let tempEntry = [
    //   {
    //     amount: {
          // currency_code: currency,
          // value: calculatedTotal,
    //       breakdown: {
    //         item_total: {
    //           currency_code: currency,
    //           value: subtotal.toFixed(2).toString(),
    //         },
    //         shipping: {
    //           currency_code: currency,
    //           value: shippingPrice.toFixed(2).toString(),
    //           method: shippingMethod,
    //         },
    //           tax: {
    //           name: "CO Sales Tax",
    //           value:salesTax,
    //           percent: 2.9
    //           },
    //       },
    //     },
    //     items: shoppingCart.map((item) => ({
    //       name: item.productName,
    //       unit_amount: {
    //         currency_code: currency,
    //         value: item.productPrice.toFixed(2).toString(),
    //       },
    //       quantity: item.quantity,
    //       product_id: item.productId,
    //     })),
    //   },
    // ];
    let  tempEntry = [{
      description: "my item",
      amount: {
        currency_code: currency,
        value: calculatedTotal,
            breakdown: {
                item_total: {
                    currency_code: currency,
                    value: subtotal.toFixed(2).toString(),
                },
                shipping: {
                  currency_code: currency,
                  value: shippingPrice.toFixed(2).toString(),
                  method: shippingMethod,
                },
    tax_total: {
      currency_code: currency,
      value: salesTax.toFixed(2).toString(),
    },
}
      }
  }] 

    entry = tempEntry;

    return actions.order.create({
      purchase_units: tempEntry,
    });
  };

  const onApproveOrder = (data, actions) => {
    console.log("ENTRY",entry)
    createOrder(
      entry,
      streetAddress,
      name,
      city,
      unit,
      state,
      country,
      postalCode,
      email,
    );
    return actions.order.capture().then((details) => {
      const name = details.payer.name.given_name;
      alert(`Transaction completed by ${name}`);
    });
  };

  return (
    <div
      style={{
        position:'fixed',
        top:'30%',
        left:mobile?'20%':'40%',
        backgroundColor:'black',
        padding:'2vw',
        boxShadow: "1px 1px 6px 2px grey",

      }}
    
    >
    <div className="checkout">
      {isPending ? (
        <p>LOADING...</p>
      ) : (
        <>
          <select
            style={{ marginBottom: "1vh" }}
            value={currency}
            onChange={onCurrencyChange}
          >
            <option value="USD">💵 USD</option>
            <option value="CAD">💶 CAD</option>
            <option value="EUR">💶 Euro</option>
          </select>
          <PayPalButtons
            style={{ layout: "vertical" }}
            createOrder={(data, actions) => onCreateOrder(data, actions)}
            onApprove={(data, actions) => onApproveOrder(data, actions)}
          />
        </>
      )}
    </div>
    </div>
  );
};

export default Checkout;
