import React, { createContext, useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { poster, formPoster } from "../../calls/calls";
import useToggle from "../../hooks/useToggle";
import { API_URL } from "../constants";

export const DataContext = createContext({});

export const DataProvider = ({ children }) => {
  const navigate = useNavigate();

  //APP WIDE STATES
  const [selectedRow, setSelectedRow] = useState(null);
  const [currentProducts, setCurrentProducts] = useState([]);
  const [currentCategories, setCurrentCategories] = useState([]);
  const [currentSubCategories, setCurrentSubCategories] = useState(null);
  const [currentImageURLs, setCurrentImageURLs] = useState([]);
  const [newProductImages, setNewProductImages] = useState([]);
  const [selectedProductImages, setSelectedProductImages] = useState([]);
  const [selectedCategoryImage, setSelectedCategoryImage] = useState(null);
  const [selectedSubCategoryImage, setSelectedSubCategoryImage] =
    useState(null);
  const [newCategoryURL, setNewCategoryURL] = useState(null);
  const [newSubCategoryURL, setNewSubCategoryURL] = useState(null);
  const [subcategoryName, setSubcategoryName] = useState(null);
  const [subcategoryDesc, setSubcategoryDesc] = useState(null);
  const [newCategoryImage, setNewCategoryImage] = useState(null);
  const [newSubCategoryImage, setNewSubCategoryImage] = useState(null);
  const [tempIndex, setTempIndex] = useState(0);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [servicesText, setServicesText] = useState("");
  const [cartOpen, toggleCartOpen] = useToggle(false);
  const [shoppingCart, setShoppingCart] = useState([]);
  const [orders, setOrders] = useState(null);
  const [totalProcessing, setTotalProcessing] = useState(null);
  const [totalCompleted, setTotalCompleted] = useState(null);
  const [totalRefunded, setTotalRefunded] = useState(null);
  const [mobile, toggleMobile] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [editCategoryMode, setEditCategoryMode] = useState(false);

  //USE EFFECTS
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let isMobile;
    windowWidth >= 768 ? (isMobile = false) : (isMobile = true);
    // console.log("mobile device:", mobile);
    toggleMobile(isMobile);
  }, []);

  //HANDLERS
  const handleAddItemToCart = (
    e,
    productId,
    productName,
    productImage,
    productPrice,
    quantity,
    length,
    width,
    height,
  ) => {
    quantity = Number(quantity);
    e.preventDefault();
    setShoppingCart((prevCart) => {
      const productIndex = prevCart.findIndex(
        (item) => item.productId === productId,
      );

      if (productIndex !== -1) {
        // Product already exists in cart, update quantity
        const updatedCart = prevCart.map((item) =>
          item.productId === productId
            ? { ...item, quantity: item.quantity + quantity }
            : item,
        );
        return updatedCart;
      } else {
        // Product does not exist in cart, add new product
        return [
          ...prevCart,
          {
            productId,
            productName,
            productImage,
            productPrice,
            quantity,
            length,
            width,
            height,
          },
        ];
      }
    });
  };

  const handleToggleCartOpen = (e) => {
    if (e) {
      toggleCartOpen(e);
    } else {
      toggleCartOpen();
    }
  };

  const handleSetNewProductImages = (e) => {
    setNewProductImages(Array.from(e.target.files));
    const selectedFiles = e.target.files;
    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewUrl = e.target.result;
        previews.push(previewUrl);
        if (previews.length === selectedFiles.length) {
          setCurrentImageURLs(previews);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSetContactInfo = (e) => {
    setAddress(e.address);
    setPhone(e.phone);
    setEmail(e.email);
  };

  const handleSetServicesInfo = (response) => {
    setServicesText(response.text);
  };

  const handleAddNewProductImagePreviews = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const newProductImagesArray = [...newProductImages];
    const currentImageURLSArray = [...currentImageURLs];
    const previews = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      const file = selectedFiles[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        const previewUrl = e.target.result;
        previews.push(previewUrl);
        setCurrentImageURLs(currentImageURLSArray.concat(previews));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSetNewCategoryImage = (e, mode = null) => {
    setNewCategoryImage(e.target.files[0]);
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const previewUrl = e.target.result;
      if (mode === "edit") {
        setSelectedCategoryImage([previewUrl]);
      } else {
        setNewCategoryURL(previewUrl);
      }
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleSetNewSubCategoryImage = (e, mode = null) => {
    setNewSubCategoryImage(e.target.files[0]);
    const selectedFile = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const previewUrl = e.target.result;
      if (mode === "edit") {
        setSelectedSubCategoryImage([previewUrl]);
      } else {
        setNewSubCategoryURL(previewUrl);
      }
    };
    reader.readAsDataURL(selectedFile);
  };

  const handleSetSubcategoryName = (e=null) => {
    if(e){
      setSubcategoryName(e.target.value);
    }
    else{
      setSubcategoryName(null);
    }
   
  };

  const handleSetSubcategoryDec = (e) => {
    if(e){
      setSubcategoryDesc(e.target.value);
    }
    else{
      setSubcategoryDesc(null);
    }
   
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    const formattedNumber = inputValue
      .replace(/\D/g, "") // Remove non-digit characters
      .replace(/^(\d{3})(\d{3})(\d{4})$/, "($1) $2-$3"); // Format as (123) 456-7890

    setPhone(formattedNumber);
  };

  const handleSetAddress = (e) => {
    setAddress(e.target.value);
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
  };

  const handleSetSelectedRow = (e) => {
    setSelectedRow(e);
  };

  const handleNavigate = (route) => {
    navigate(route);
  };

  //API CALLS

  const createProduct = (
    product_name,
    product_desc,
    product_material,
    product_length,
    product_width,
    product_height,
    product_color,
    product_price,
    product_weight,
    product_quantity,
    product_category,
    product_subcategory,
  ) => {
    var formData = new FormData();
    if (newProductImages !== null) {
      for (let i = 0; i < newProductImages.length; i++) {
        formData.append(`file`, newProductImages[i]);
      }
      let URL = API_URL + "/createProduct";
      let outpack = {
        product_name: product_name,
        product_desc: product_desc,
        product_material: product_material,
        product_length: product_length,
        product_width: product_width,
        product_height: product_height,
        product_color: product_color,
        product_price: product_price,
        product_weight: product_weight,
        product_quantity: product_quantity,
        product_category: product_category,
        product_subcategory: product_subcategory,
      };
      console.log(outpack);
      formPoster(URL, formData, outpack).then((response) => {
        if (response.status_code === 200) {
          fetchProducts();
        }
      });
    }
  };

  const editProduct = (
    product_ID,
    product_name,
    product_desc,
    product_material,
    product_length,
    product_width,
    product_height,
    product_color,
    product_price,
    product_weight,
    product_quantity,
    product_category,
    product_subcategory,
  ) => {
    let URL = API_URL + "/editProduct";
    let outpack = {
      product_ID: product_ID,
      name: product_name,
      description: product_desc,
      material: product_material,
      product_length: product_length,
      product_width: product_width,
      product_height: product_height,
      color: product_color,
      price: product_price,
      weight: product_weight,
      quantity: product_quantity,
      category_id: product_category,
      subcategory_id: product_subcategory,
      product_images: selectedProductImages,
    };
    var formData = new FormData();
    if (newProductImages !== null) {
      for (let i = 0; i < newProductImages.length; i++) {
        formData.append(`file`, newProductImages[i]);
      }
    }
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status_code === 200) {
        fetchProducts();
      }
    });
  };

  const createOrder = (
    order_details,
    streetAddress,
    name,
    city,
    unit,
    state,
    country,
    postalCode,
    email,
  ) => {
    let URL = API_URL + "/create_order";
    let outpack = {
      order_details: order_details,
      streetAddress: streetAddress,
      name: name,
      city: city,
      unit: unit,
      state: state,
      country: country,
      postalCode: postalCode,
      email: email,
    };
    console.log("OUTPACK", outpack);
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        console.log(response);
        fetchProducts();
      }
    });
  };

  const deleteProduct = (product_ID) => {
    let URL = API_URL + "/deleteProduct";
    let outpack = {
      product_ID: product_ID,
    };
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        console.log(response);
        fetchProducts();
      }
    });
  };

  const fetchProducts = (category_id=null,subcategory_id=null) => {
    let URL = API_URL + "/fetchProducts";
    let outpack={
      category_id:category_id,
      subcategory_id:subcategory_id
    };
    console.log(outpack)
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        console.log(response);
        setCurrentProducts(response.products);
      }
    });
  };

  const fetchAdminProducts = () => {
    let URL = API_URL + "/fetchAdminProducts";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        console.log(response);
        setCurrentProducts(response.products);
      }
    });
  };

  const fetchAdminStats = () => {
    let URL = API_URL + "/fetchAdminStats";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        console.log(response);
        setTotalProcessing(response.total_processing);
        setTotalCompleted(response.total_completed);
        setTotalRefunded(response.total_refunded);
      }
    });
  };

  const fetchCategories = () => {
    let URL = API_URL + "/fetchCategories";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        console.log(response);
        setCurrentCategories(response.categories);
      }
    });
  };

  const fetchSubCategories = (category_id) => {
    let URL = API_URL + "/fetchSubCategories";
    let outpack = {category_id:category_id};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        console.log(response);
        setCurrentSubCategories(response.subcategories);
      }
    });
  };

  const deleteCategory = (category_ID,mode, id=null) => {
    let URL = API_URL + "/deleteCategory";
    let outpack = {
      category_ID: category_ID,
      mode:mode
    };
    console.log(outpack)
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        fetchCategories();
        if (id){
          console.log('fetching subs')
          fetchSubCategories(id)
        }
      }
    });
  };

  const createCategory = (
    category_name,
    category_desc,
    mode = null,
    category_id = null,
  ) => {
    var formData = new FormData();
    if (mode === "sub") {
      if (newSubCategoryImage !== null) {
        formData.append(`file`, newSubCategoryImage);
      }
    } else {
      mode = "main";
      if (newCategoryImage !== null) {
        formData.append(`file`, newCategoryImage);
      }
    }

    let URL = API_URL + "/createCategory";
    let outpack = {
      category_name: category_name,
      category_desc: category_desc,
      category_mode: mode,
      category_id: category_id,
    };
    console.log(outpack);
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status_code === 200) {
        fetchCategories();
        if (category_id){
          console.log('fetching subs')
          fetchSubCategories(category_id)
        }
      }
    });
  };

  const editCategory = (category_id, category_name, category_desc, mode='main',id=null) => {
    var formData = new FormData();
    if (newCategoryImage !== null) {
      formData.append(`file`, newCategoryImage);
    }
    let URL = API_URL + "/editCategory";
    let currentImage;
    if (selectedCategoryImage.length < 1) {
      currentImage = null;
    } else {
      currentImage = selectedCategoryImage;
    }
    let outpack = {
      category_id: category_id,
      category_name: category_name,
      category_desc: category_desc,
      mode:mode
    };
    formPoster(URL, formData, outpack).then((response) => {
      if (response.status_code === 200) {
        fetchCategories();
        if(id){
          fetchSubCategories(id)
        }
      }
    });
  };

  const updateContactInfo = () => {
    let URL = API_URL + "/updateContactInfo";
    let outpack = {
      address: address,
      phone: phone,
      email: email,
    };
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        // fetchCategories();
      }
    });
  };

  const updateOrder = (order_id, tracking_number, status) => {
    let URL = API_URL + "/UpdateOrder";
    let outpack = {
      order_id: order_id,
      tracking_number: tracking_number,
      status: status,
    };
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        fetchOrders();
      }
    });
  };

  const fetchOrders = () => {
    let URL = API_URL + "/fetchOrders";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        setOrders(response);
      }
    });
  };

  const fetchContactInfo = () => {
    let URL = API_URL + "/fetchContactInfo";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status_code === 200) {
        handleSetContactInfo(response);
      }
    });
  };

  const fetchServicesInfo = () => {
    let URL = API_URL + "/fetchServicesInfo";
    let outpack = {};
    poster(outpack, URL).then((response) => {
      if (response.status === 200) {
        handleSetServicesInfo(response);
      }
    });
  };

  const updateServicesInfo = (text) => {
    let URL = API_URL + "/updateServicesInfo";
    let outpack = {
      text: text,
    };
    poster(outpack, URL).then((response) => {
      if (response.status === 200) {
        return;
      }
    });
  };

  const value = {
    handleAddNewProductImagePreviews,
    editProduct,
    handleSetNewProductImages,
    fetchProducts,
    deleteProduct,
    handleNavigate,
    handleSetSelectedRow,
    handleSetNewCategoryImage,
    createCategory,
    fetchCategories,
    deleteCategory,
    editCategory,
    updateContactInfo,
    fetchContactInfo,
    fetchServicesInfo,
    updateServicesInfo,
    address,
    setAddress,
    handleSetAddress,
    phone,
    setPhone,
    handlePhoneNumberChange,
    email,
    setEmail,
    handleEmailChange,
    selectedCategoryImage,
    setSelectedCategoryImage,
    selectedRow,
    setSelectedRow,
    currentProducts,
    createProduct,
    newProductImages,
    setNewProductImages,
    currentImageURLs,
    setCurrentImageURLs,
    tempIndex,
    setTempIndex,
    selectedProductImages,
    setSelectedProductImages,
    currentCategories,
    setCurrentCategories,
    newCategoryURL,
    setNewCategoryURL,
    newCategoryImage,
    setNewCategoryImage,
    servicesText,
    setServicesText,
    cartOpen,
    toggleCartOpen,
    handleToggleCartOpen,
    shoppingCart,
    setShoppingCart,
    handleAddItemToCart,
    createOrder,
    fetchAdminProducts,
    fetchOrders,
    orders,
    fetchAdminStats,
    totalProcessing,
    setTotalProcessing,
    totalCompleted,
    setTotalCompleted,
    totalRefunded,
    setTotalRefunded,
    updateOrder,
    mobile,
    handleSetNewSubCategoryImage,
    editCategoryMode,
    setEditCategoryMode,
    selectedSubCategoryImage,
    setSelectedSubCategoryImage,
    newSubCategoryImage,
    setNewSubCategoryImage,
    newSubCategoryURL,
    setNewSubCategoryURL,
    subcategoryName,
    setSubcategoryName,
    subcategoryDesc,
    setSubcategoryDesc,
    handleSetSubcategoryName,
    handleSetSubcategoryDec,
    setNewSubCategoryImage,
    fetchSubCategories,
    currentSubCategories, setCurrentSubCategories,
  };

  return value ? (
    <DataContext.Provider value={value}>{children}</DataContext.Provider>
  ) : null;
};
