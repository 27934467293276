import React, { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";

export const ServicesText = () => {
  const { servicesText, setServicesText, fetchServicesInfo } =
    useContext(DataContext);

  useEffect(() => {
    console.log(servicesText);
    fetchServicesInfo();
  }, []);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
        }}
      >
        <h1 style={{ paddingTop: "8vh", textAlign: "center", color: "black" }}>
          Services
        </h1>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "80vh",
            width: "90%",
            height: "90%",
            backgroundColor: "ghostwhite",
            boxShadow: "1px 1px 6px 2px black",
          }}
        >
          <p>{servicesText}</p>
        </div>
      </div>
    </>
  );
};
