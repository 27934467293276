import React, { Component, useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext/index";
import logo from "../../static/images/stately_sticker_logo2.png";

import "./styles.css";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { fetcher, poster } from "../../calls/calls";
import { API_URL } from "../../common/constants";
import { ShoppingCartModal } from "../../components/common";

export const Main = () => {
  const { mobile } = useContext(DataContext);

  useEffect(() => {
    let outpack = {};
    let url = API_URL + "/time";
    console.log(url);
    poster(outpack, url).then((response) => console.log(response));
  }, []);

  return (
    <>
      <Header />
      <ShoppingCartModal />
      <div class="main">
        <img
          // class="splashLogo"
          style={{
            marginTop: mobile ? "22vh" : "3vh",
            width: mobile ? "90%" : "60%",
            marginBottom: "0%",
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
          }}
          src={logo}
        />
        {/* <h1 class="title" style={{ color: "rgb(0,0,0)" }}>
          Stately Stickers
        </h1> */}
        {/* <h1 class ="sub-title">CUTTING THE CORDS ON REAL-TIME TRACKING-</h1> */}
      </div>
      <Footer />
    </>
  );
};
