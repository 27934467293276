import { useState, useContext, useEffect } from "react";
import { DataContext } from "../../common/DataContext";
import { AuthContext } from "../../common/AuthContext";
import products_icon from "../../static/products_icon.png";
import categories_icon from "../../static/categories_icon.png";
import orders_icon from "../../static/orders_icon.png";
import settings_icon from "../../static/settings_icon.png";
import logout_icon from "../../static/logout_icon.png";
import dashboard_icon from "../../static/dashboard_icon.png";
import products_icon_black from "../../static/products_icon_black.png";
import categories_icon_black from "../../static/categories_icon_black.png";
import orders_icon_black from "../../static/orders_icon_black.png";
import settings_icon_black from "../../static/settings_icon_black.png";
import logout_icon_black from "../../static/logout_icon_black.png";
import dashboard_icon_black from "../../static/dashboard_icon_black.png";
import useToggle from "../../hooks/useToggle";
import { useNavigate } from "react-router-dom";
import { poster } from "../../calls/calls";
import closeIcon from "../../static/images/close_icon.png";
import Checkout from "../../checkout";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
export const Sidebar = (props) => {
  const { selectedRow, handleNavigate } = useContext(DataContext);
  const {
    logInUser,
    email,
    setEmail,
    password,
    setPassword,
    redirect,
    logout,
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
  };

  return (
    <div
      style={{
        width: "18vw",
        backgroundColor: "black",
        display: "flex",
        flexDirection: "column",
        padding:'1vw'
        // alignItems:'center'
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <h1 style={{ color: "white" }}>Admin Portal</h1>
      </div>
      <SidebarRow
        title={"Dashboard"}
        value={"Dashboard"}
        whiteIcon={dashboard_icon}
        blackIcon={dashboard_icon_black}
        route={"/adminDashboard"}
        selectedRow={selectedRow}
        handler={handleNavigate}
      />
      <SidebarRow
        title={"Products"}
        whiteIcon={products_icon}
        blackIcon={products_icon_black}
        value={"Products"}
        route={"/adminProducts"}
        selectedRow={selectedRow}
        handler={handleNavigate}
      />
      <SidebarRow
        title={"Categories"}
        value={"Categories"}
        whiteIcon={categories_icon}
        blackIcon={categories_icon_black}
        route={"/adminCategories"}
        selectedRow={selectedRow}
        handler={handleNavigate}
      />
      <SidebarRow
        title={"Orders"}
        value={"Orders"}
        whiteIcon={orders_icon}
        blackIcon={orders_icon_black}
        route={"/adminOrders"}
        selectedRow={selectedRow}
        handler={handleNavigate}
      />
      {/* <SidebarRow
          title={"Admins"}
          icon={products_icon}
        /> */}
      <SidebarRow
        title={"Settings"}
        value={"Settings"}
        whiteIcon={settings_icon}
        blackIcon={settings_icon_black}
        route={"/adminSettings"}
        selectedRow={selectedRow}
        handler={handleNavigate}
      />
      <SidebarRow
        title={"Logout"}
        value={"Logout"}
        whiteIcon={logout_icon}
        blackIcon={logout_icon_black}
        handler={handleLogout}
      />
    </div>
  );
};

export const SidebarRow = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "1vh",
          padding:'1vh',
          backgroundColor:
            props.value === props.selectedRow ? "#e9eff3" : "black",
        }}
        onClick={() => props.handler(props.route)}
      >
        <div
          style={{
            marginLeft: "1vw",
            display: "flex",
            flexDirection: "row",
            marginBottom: "1vh",
            height: "100%",
            alignItems: "center",
          }}
        >
          <img
            src={
              props.value === props.selectedRow
                ? props.blackIcon
                : props.whiteIcon
            }
            style={{
              width: "3vw",
              height: "3vw",
              marginLeft: "2w",
            }}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <h1
            style={{
              color: props.value === props.selectedRow ? "black" : "white",
              marginLeft: "2vw",
            }}
          >
            {props.title}
          </h1>
        </div>
      </div>
    </>
  );
};

export const ConfirmModal = (props) => {
  return (
    <>
      {props.modalState === true ? (
        <>
          <div
            style={{
              position: "fixed",
              top: "40%",
              left: "40rem",
              width: "40%",
              height: "auto",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: "9999",
              padding: "1vw",
              boxShadow: "1px 1px 6px 2px black",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "auto",
                // backgroundColor:'lightgreen',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <h1>
                <strong>{props.question}</strong>
              </h1>
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                // backgroundColor:'lightblue',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={props.modalHandler}
              />

              <StyledButton
                buttonAction={props.confirmAction}
                buttonText={"OK"}
              />
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export const StyledButton = (props) => {
  return (
    <>
      <div
        onClick={() => props.buttonAction()}
        style={{
          width: "auto",
          height: "3vh",
          backgroundColor: "black",
          marginRight: "1vw",
          marginLeft: "1vw",
          display: "flex",
          padding: ".5%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h2 style={{ color: "white" }}>{props.buttonText}</h2>
      </div>
    </>
  );
};

export const ShoppingCartModal = () => {
  const { mobile } = useContext(DataContext);
  const shippingOptions = {
    USA: [
      {
        name: "USPS ground - padded envelope",
        price: 5,
        delivery: "3-5 days",
        width: 4,
        length: 8,
        height: 0.25,
      },
      {
        name: "USPS Flat Rate Envelope",
        price: 9,
        delivery: "3-5 days",
        width: 9.5,
        length: 12.5,
        height: 0.25,
      },
      {
        name: "USPS Flat Rate box - small",
        price: 10,
        delivery: "3-5 days",
        width: 5,
        length: 9,
        height: 1.75,
      },
      {
        name: "USPS Flat Rate box - medium",
        price: 15,
        delivery: "3-5 days",
        width: 8.75,
        length: 11.25,
        height: 6,
      },
      {
        name: "USPS Flat Rate box - large",
        price: 20,
        delivery: "3-5 days",
        width: 12.25,
        length: 12.25,
        height: 6,
      },
      {
        name: "USPS Priority Mail Express Flat Rate Envelope",
        price: 30,
        delivery: "1-3 days",
        width: 9.5,
        length: 12.5,
        height: 0.25,
      },
    ],
    Canada: [
      {
        name: "Canada Post Regular",
        price: 10.95,
        delivery: "5-7 days",
        width: 9.5,
        length: 12.5,
        height: 0.25,
      },
      {
        name: "FedEx International",
        price: 20.95,
        delivery: "3-5 days",
        width: 9.5,
        length: 12.5,
        height: 0.25,
      },
      { name: "DHL Express", price: 25.95, delivery: "2-4 days" },
    ],
  };

  const countries = {
    USA: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Islands",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming",
    ],
    Canada: [
      "Alberta",
      "British Columbia",
      "Manitoba",
      "New Brunswick",
      "Newfoundland and Labrador",
      "Northwest Territories",
      "Nova Scotia",
      "Nunavut",
      "Ontario",
      "Prince Edward Island",
      "Quebec",
      "Saskatchewan",
      "Yukon",
    ],
  };

  const payment_methods = ["PayPal"];
  // Australia: [
  //   "Australian Capital Territory", "New South Wales", "Northern Territory", "Queensland",
  //   "South Australia", "Tasmania", "Victoria", "Western Australia"
  // ],
  // NewZealand: [
  //   "Auckland", "Bay of Plenty", "Canterbury", "Gisborne", "Hawke's Bay", "Manawatu-Wanganui",
  //   "Marlborough", "Nelson", "Northland", "Otago", "Southland", "Taranaki",
  //   "Tasman", "Waikato", "Wellington", "West Coast"
  // ]
  const {
    cartOpen,
    toggleCartOpen,
    shoppingCart,
    setShoppingCart,
    handleToggleCartOpen,
  } = useContext(DataContext);

  const [country, setCountry] = useState("");
  const [billingCountry, setBillingCountry] = useState("");
  const [stateProvince, setStateProvince] = useState("");
  const [billingStateProvince, setBillingStateProvince] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [billingAddress, setBillingAddress] = useState("");
  const [unitNumber, setUnitNumber] = useState("");
  const [billingUnitNumber, setBillingUnitNumber] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [billingPostalCode, setBillingPostalCode] = useState("");
  const [shippingMethod, setShippingMethod] = useState("");
  const [city, setCity] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [salesTax, setSalesTax] = useState(null);
  const [shippingPrice, setShippingPrice] = useState(null);
  const [filteredShippingOptions, setFilteredShippingOptions] = useState(null);
  const [subtotal, setSubtotal] = useState(null);
  const [total, setTotal] = useState(null);
  const [displayTotal, setDisplayTotal] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [sameAsShiiping, toggleSameAsShipping] = useToggle(false);
  const [shippingName, setShippingName] = useState(null);
  const [nameOnCard, setNameOnCard] = useState(null);
  const [email, setEmail] = useState(null);
  console.log("process.env.REACT_APP_CLIENT_ID",process.env.REACT_APP_CLIENT_ID)
  const initialOptions = {
    "client-id":
      process.env.REACT_APP_CLIENT_ID,
    currency: "USD",
    intent: "capture",
  };
  console.log("initialOptions",initialOptions)

  useEffect(() => {
    if (country) {
      const totalDimensions = calculateTotalDimensions(shoppingCart);
      const filteredOptions = filterShippingOptions(
        totalDimensions,
        shippingOptions[country],
      );
      setFilteredShippingOptions(filteredOptions);
      console.log(totalDimensions);
    }
    let subtot = Number(
      shoppingCart
        .reduce((total, item) => total + item.productPrice * item.quantity, 0)
        .toFixed(2),
    );
    setSubtotal(subtot);
    setSalesTax(0.029 * subtot);
  }, [country, shoppingCart]);

  useEffect(() => {
    if (shippingPrice !== null) {
      setDisplayTotal(Number(subtotal + shippingPrice + salesTax));
      setTotal(Number(subtotal + shippingPrice+ salesTax))
    }
  }, [shippingPrice]);

  useEffect(() => {
   console.log(salesTax)
  }, [salesTax]);

  const calculateTotalDimensions = (cartItems) => {
    let totalWidth = 0;
    let totalLength = 0;
    let totalHeight = 0;

    cartItems.forEach((item) => {
      console.log("ITEM", item);
      if (item.height < 1) {
        totalLength += item.length;
        totalWidth = Math.max(totalWidth, item.width); // width remains max of all widths
        totalHeight = Math.max(totalHeight, item.height); // height remains max of all heights
      } else {
        totalLength += item.length * item.quantity;
        totalWidth += item.width * item.quantity;
        totalHeight += item.height * item.quantity;
      }
    });

    return { totalWidth, totalLength, totalHeight };
  };

  const filterShippingOptions = (totalDimensions, options) => {
    return options.filter(
      (option) =>
        option.width >= totalDimensions.totalWidth &&
        option.length >= totalDimensions.totalLength &&
        option.height >= totalDimensions.totalHeight,
    );
  };

  const removeAllFromCart = (e, productId) => {
    setShoppingCart((prevCart) =>
      prevCart.filter((item) => item.productId !== productId),
    );
  };

  const updateQuantity = (e, productId, op = null) => {
    let quantityNumber = Number(e.target.value);
    if (op) {
      if (op === "+") {
        quantityNumber += 1;
      }
      if (op === "-") {
        quantityNumber -= 1;
      }
    }

    setShoppingCart((prevCart) => {
      if (quantityNumber === 0) {
        return prevCart.filter((item) => item.productId !== productId);
      } else {
        return prevCart.map((item) =>
          item.productId === productId
            ? { ...item, quantity: quantityNumber }
            : item,
        );
      }
    });
  };

  const handleToggleSameAsShipping = (e) => {
    if (e) {
      toggleSameAsShipping(e);
    } else {
      toggleSameAsShipping();
    }
  };

  const handleSetEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSetPaymentMethod = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleSetShippingAddress = (e) => {
    setStreetAddress(e.target.value);
  };

  const handleSetBillingAddress = (e) => {
    setBillingAddress(e.target.value);
  };

  const handleSetShippingName = (e) => {
    setShippingName(e.target.value);
  };

  const handleSetNameOnCard = (e) => {
    setNameOnCard(e.target.value);
  };

  const handleSetUnitNumber = (e) => {
    setUnitNumber(e.target.value);
  };
  const handleSetBillingUnitNumber = (e) => {
    setBillingUnitNumber(e.target.value);
  };

  const handleSetCity = (e) => {
    setCity(e.target.value);
  };

  const handleSetBillingCity = (e) => {
    setBillingCity(e.target.value);
  };

  const handleSetBillingPostalCode = (e) => {
    setBillingPostalCode(e.target.value);
  };
  const handleSetPostalCode = (e) => {
    setPostalCode(e.target.value);
  };

  const handleSetBillingStateProvince = (e) => {
    setBillingStateProvince(e.target.value);
  };

  const handleSetStateProvince = (e) => {
    setStateProvince(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
    setStateProvince(""); // Reset state/province when country changes
    setShippingMethod(""); // Reset shipping method when country changes
  };

  const handleShippingMethodChange = (e) => {
    let value = e.target.value.split(",");
    setShippingMethod(value[0]);
    setShippingPrice(Number(value[1]));
  };

  return (
    <>
      {cartOpen && cartOpen ? (
        <>
          <div
            style={{
              position: "fixed",
              left: mobile ? "5vw" : "25vw",
              top: mobile ? "7vh" : "12vh",
              width: mobile ? "90vw" : "50vw",
              height: mobile ? "68vh" : "auto",
              padding: mobile ? "" : "1vw",
              backgroundColor: "ghostwhite",
              marginTop:'1vh',
              display: "flex",
              flexDirection: "column",
              // alignItems: "center",
              // justifyContent: "top",

              boxShadow: "1px 1px 6px 2px black",
              zIndex: "999",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "auto",

                // backgroundColor:'red',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "top",
                // marginBottom: "1vh",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: mobile ? ".5vh" : "2%",
                  width: "2vh",
                  height: "2vh",
                  // backgroundColor: "black",
                }}
                onClick={() => handleToggleCartOpen()}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>
              <label
                style={{
                  fontSize: mobile ? "1.25rem" : "1.5rem",
                  color: "black",
                  fontWeight: 700,
                  textAlign: "center",
                  marginBottom: "1vh",
                }}
              >
                {"Shopping Cart"}
              </label>
            </div>

            <div
              style={{
                width: "100%",
                height: "100vh",
                // backgroundColor:'green',
                display: "flex",
                flexDirection: "row",
                // alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: mobile ? "46%" : "60%",
                  height: mobile ? "56vh" : "100%",
                  minHeight: mobile ? "" : "64vh",
                  // backgroundColor:'red',
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "top",
                  overflowY:'scroll',
                  padding: "1vh",
                  gap: "2vh",
                }}
              >
                {shoppingCart.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",

                      alignItems: "center",
                      justifyContent: "center",
                      width: mobile ? "98%" : "80%",
                      height: mobile ? "25vh" : "auto",
                      boxShadow: "1px 1px 6px 2px grey",
                      // padding:'1vw'
                    }}
                  >
                    <div
                      style={{
                        width: mobile ? "14vw" : "98%",
                        // backgroundColor:'grey',
                        height: mobile ? "vh" : "auto",
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: "1vh",
                        marginRight: mobile ? "2vw" : "",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={item.productImage[0]?.thumbnail_url}
                        alt={item.productName}
                        style={{
                          height: mobile ? "8vh" : "15vh",
                          width: "auto",
                          display: "block",
                        }}
                      />
                    </div>

                    {!mobile ? (
                      <>
                        <label>
                          <strong>{item.productName}</strong> <br />
                          <br />
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: mobile ? "" : "2vw",
                              marginBottom: mobile ? "" : "1vh",
                            }}
                          >
                            <strong>Quantity:</strong>

                            <input
                              value={item.quantity}
                              min="0"
                              onChange={(e) =>
                                updateQuantity(e, item.productId)
                              }
                              type="number"
                              style={{
                                // marginLeft: "1vw",
                                width: "2vw",
                                height: "2vh",
                              }}
                            ></input>

                            <div
                              onClick={(e) =>
                                removeAllFromCart(e, item.productId)
                              }
                            >
                              <strong>{"Remove All"}</strong>
                            </div>
                          </div>
                        </label>
                        <label>
                          <strong>Subtotal:</strong> $
                          {(item.productPrice * item.quantity).toFixed(2)}
                        </label>
                      </>
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: mobile ? ".8rem" : "1rem",
                              color: "black",
                              fontWeight: 700,
                              textAlign: "center",
                              marginBottom: ".5vh",
                            }}
                          >
                            <strong>{item.productName}</strong>
                          </label>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            marginBottom: ".5vh",
                            gap: "2vw",
                          }}
                        >

                          <label
                            style={{
                              // backgroundColor:'red',
                              height: "auto",
                              fontSize: mobile ? ".8rem" : "1.25rem",
                              textAlign: "center",
                              padding: "0",
                            }}
                          >
                            {item.quantity}
                          </label>
                          <button
                            value={item.quantity}
                            onClick={(e) =>
                              updateQuantity(e, item.productId, "-")
                            }
                          >
                            -
                          </button>

                          <button
                            value={item.quantity}
                            onClick={(e) =>
                              updateQuantity(e, item.productId, "+")
                            }
                          >
                            +
                          </button>

                          <div
                            onClick={(e) =>
                              removeAllFromCart(e, item.productId)
                            }
                          >
                            <strong>
                              <label
                                style={{
                                  fontSize: mobile ? ".8rem" : "1rem",
                                  color: "black",
                                  fontWeight: 500,
                                  textAlign: "center",
                                }}
                              >
                                Remove All
                              </label>
                            </strong>
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <label
                            style={{
                              fontSize: mobile ? ".8rem" : "1rem",
                              color: "black",
                              fontWeight: 500,
                              textAlign: "center",
                            }}
                          >
                            <strong>Subtotal:</strong> $
                            {(item.productPrice * item.quantity).toFixed(2)}
                          </label>
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </div>

              <div
                style={{
                  width: "40%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "top",
                }}
              >
                <div
                  style={{
                    width: "96%",
                    height: "98%",
                    display: "flex",
                    flexDirection: "column",
                    // overflowY: "scroll",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: ".5vh",
                      paddingTop: "1vh",
                    }}
                  >
                    <label
                      style={{
                        fontSize: mobile ? ".8rem" : "1.25rem",
                        marginRight: "1vw",
                      }}
                    >
                      <strong>Items:</strong>
                    </label>
                    <label
                      style={{
                        fontSize: mobile ? ".8rem" : "1.25rem",
                        marginRight: "1vw",
                      }}
                    >
                      {shoppingCart.length}
                    </label>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: ".52vh",
                    }}
                  >
                    <label
                      style={{
                        marginRight: "1vw",
                        fontSize: mobile ? ".8rem" : "1.25rem",
                      }}
                    >
                      <strong>Subtotal:</strong>
                    </label>
                    <label
                      style={{
                        fontSize: mobile ? ".8rem" : "1.25rem",
                        marginRight: "1vw",
                      }}
                    >
                      ${subtotal}
                    </label>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: ".5vh",
                      gap:'1vh'
                    }}
                  >
                    <label
                      style={{
                        // marginBottom: "1vh",
                        marginRight: "1vw",
                        fontSize: mobile ? ".8rem" : "1.25rem",
                      }}
                    >
                      <strong>Shipping Info:</strong>
                    </label>


                    <input
                      type="text"
                      placeholder="Name"
                      onChange={(e) => handleSetShippingName(e)}
                      value={shippingName}
                      style={{
                        height:mobile?'2vh':'',
                        fontSize: mobile ? "16px" : "inherit",

                      }}
                    ></input>
                    <input
                      type="email"
                      placeholder="Email"
                      onChange={(e) => handleSetEmail(e)}
                      value={email}
                      style={{
                        height:mobile?'2vh':'',
                        fontSize: mobile ? "16px" : "inherit",

                      }}
                    ></input>
                    <input
                      type="text"
                      placeholder="Address"
                      value={streetAddress}
                      onChange={(e) => handleSetShippingAddress(e)}
                      style={{
                        height:mobile?'2vh':'',
                        fontSize: mobile ? "16px" : "inherit",

                      }}
                    ></input>
                    <input
                      type="text"
                      placeholder="Unit #"
                      value={unitNumber}
                      onChange={(e) => handleSetUnitNumber(e)}
                      style={{
                        height:mobile?'2vh':'',
                        fontSize: mobile ? "16px" : "inherit",

                      }}
                    ></input>
                    <input
                      type="text"
                      placeholder="City"
                      value={city}
                      onChange={(e) => handleSetCity(e)}
                      style={{
                        height:mobile?'2vh':'',
                        fontSize: mobile ? "16px" : "inherit",

                      }}
                    ></input>
                    <input
                      type="text"
                      value={postalCode}
                      onChange={(e) => handleSetPostalCode(e)}
                      placeholder="Postal Code"
                      style={{
                        height:mobile?'2vh':'',
                        fontSize: mobile ? "16px" : "inherit",

                      }}
                    ></input>
                    <select
                      value={stateProvince}
                      onChange={(e) => handleSetStateProvince(e)}
                      disabled={!country}
                      style={{

                      }}
                    >
                      <option value="">Select State/Province</option>
                      {country &&
                        countries[country].map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                    </select>

                    <select
                      value={country}
                      onChange={handleCountryChange}
                      style={{

                      }}
                    >
                      <option value="">Select Country</option>
                      {Object.keys(countries).map((countryKey) => (
                        <option key={countryKey} value={countryKey}>
                          {countryKey}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) => handleShippingMethodChange(e)}
                      disabled={
                        !country ||
                        shoppingCart.length === 0 ||
                        !shippingName ||
                        !streetAddress ||
                        !stateProvince ||
                        !postalCode ||
                        !city ||
                        !email
                      }
                      style={
                        {
                          // marginBottom: "1vh",
                        }
                      }
                    >
                      <option value="">Shipping & Handling Method</option>
                      {filteredShippingOptions !== null
                        ? filteredShippingOptions.map((option, index) => (
                            <option
                              key={index}
                              value={[option.name, option.price]}
                            >
                              {option.name} - ${option.price.toFixed(2)} (
                              {option.delivery})
                            </option>
                          ))
                        : null}
                    </select>
                    <div
                      style={{
                        width: "100%",
                        height: "auto",
                        display: "flex",
                        flexDirection: "row",
                        // backgroundColor:'grey'
                      }}
                    >
                      <label
                        style={{
                          // marginBottom: "1vh",
                          marginRight: "1vw",
                          fontSize: mobile ? ".8rem" : "1.25rem",
                        }}
                      >
                        <strong>
                          {mobile
                            ? "Shipping total:"
                            : "Shipping & Handling total:"}
                        </strong>
                      </label>

                      <label
                        style={{
                          marginRight: "1vw",
                          fontSize: mobile ? ".8rem" : "1.25rem",
                        }}
                      >
                        {`$${
                          shippingPrice && shippingPrice !== null
                            ? shippingPrice.toFixed(2)
                            : "0.00"
                        }`}
                      </label>


                    </div>


                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      // marginBottom: ".5vh",
                      // backgroundColor:'honeydew',
                    }}
                  >
                    <label
                      style={{
                        marginRight: "1vw",
                        fontSize: mobile ? ".8rem" : "1.25rem",
                      }}
                    >
                      <strong>CO Sales Tax:</strong>
                    </label>
                    <label
                      style={{
                        marginRight: "1vw",
                        fontSize: mobile ? ".8rem" : "1.25rem",
                      }}
                    >
                      {`$${
                        salesTax && salesTax !== null
                          ? salesTax.toFixed(2)
                          : "0.00"
                      }`}
                    </label>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "auto",
                      display: "flex",
                      flexDirection: "row",
                      // marginBottom: ".5vh",
                      // backgroundColor:'honeydew',
                    }}
                  >
                    <label
                      style={{
                        marginRight: "1vw",
                        fontSize: mobile ? ".8rem" : "1.25rem",
                      }}
                    >
                      <strong>Total Price:</strong>
                    </label>
                    <label
                      style={{
                        marginRight: "1vw",
                        fontSize: mobile ? ".8rem" : "1.25rem",
                      }}
                    >
                      {`$${
                        displayTotal && displayTotal !== null ? total.toFixed(2) : "0.00"
                      }`}
                    </label>
                  </div>
                      {!mobile?
                      <>
                                        <label
                    style={{
                      marginRight: "1vw",
                      fontSize: mobile ? ".8rem" : "1.25rem",
                    }}
                  >
                    <strong>
                      {"Payment Information:"}
                    </strong>
                  </label>
                      </>
                      :
                      null

                      }


                  <select
                    onChange={(e) => handleSetPaymentMethod(e)}
                    disabled={
                      !country ||
                      shoppingCart.length === 0 ||
                      !shippingName ||
                      !streetAddress ||
                      !stateProvince ||
                      !postalCode ||
                      !city ||
                      !email ||
                      !shippingMethod
                    }
                    style={{
                      marginTop: ".5vh",
                      marginBottom: "1vh",
                    }}
                  >
                    <option value="">Select Payment Method</option>
                    {payment_methods !== null
                      ? payment_methods.map((name) => (
                          <option key={name} value={name}>
                            {name}
                          </option>
                        ))
                      : null}
                  </select>

                  {paymentMethod && paymentMethod === "Credit/Debit" ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <label style={{ marginRight: "1vw" }}>
                          <strong>Billing address same as shipping</strong>
                        </label>
                        <input
                          type="checkbox"
                          value={sameAsShiiping}
                          onChange={(e) => handleToggleSameAsShipping()}
                        ></input>
                      </div>

                      {sameAsShiiping && sameAsShiiping === true ? null : (
                        <>
                          <input
                            type="text"
                            value={billingAddress}
                            placeholder="Billing Address"
                            onChange={(e) => handleSetBillingAddress(e)}
                            style={{
                              marginBottom: "1vh",
                            }}
                          ></input>
                          <input
                            type="text"
                            placeholder="Unit #"
                            value={billingUnitNumber}
                            onChange={(e) => handleSetBillingUnitNumber(e)}
                            style={{
                              marginBottom: "1vh",
                            }}
                          ></input>
                          <input
                            type="text"
                            placeholder="City"
                            value={billingCity}
                            onChange={(e) => handleSetBillingCity(e)}
                            style={{
                              marginBottom: "1vh",
                            }}
                          ></input>
                          <input
                            type="text"
                            placeholder="Postal Code"
                            value={billingPostalCode}
                            onChange={(e) => handleSetBillingPostalCode(e)}
                            style={{
                              marginBottom: "1vh",
                            }}
                          ></input>
                          <select
                            value={billingStateProvince}
                            onChange={(e) => handleSetBillingStateProvince(e)}
                            disabled={!country}
                            style={{
                              marginBottom: "1vh",
                            }}
                          >
                            <option value="">Select State/Province</option>
                            {country &&
                              countries[country].map((state) => (
                                <option key={state} value={state}>
                                  {state}
                                </option>
                              ))}
                          </select>
                        </>
                      )}
                      <label>
                        <strong>Card Info:</strong>
                      </label>
                      <input
                        type="text"
                        placeholder="Name on card"
                        value={nameOnCard}
                        onChange={(e) => handleSetNameOnCard(e)}
                        style={{
                          marginBottom: "1vh",
                        }}
                      ></input>
                    </>
                  ) : null}
                  {paymentMethod && paymentMethod == "PayPal" ? (
                    <>
                      <div
                        style={{
                          width: "100%",
                          height: "auto",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <PayPalScriptProvider options={initialOptions}>
                          <Checkout
                            shoppingCart={shoppingCart}
                            subtotal={subtotal}
                            salesTax={salesTax}
                            total={total}
                            shippingMethod={shippingMethod}
                            shippingPrice={shippingPrice}
                            streetAddress={streetAddress}
                            name={shippingName}
                            city={city}
                            unit={unitNumber}
                            state={stateProvince}
                            country={country}
                            postalCode={postalCode}
                            email={email}
                          />
                        </PayPalScriptProvider>
                      </div>
                    </>
                  ) : null}

                  {/* <button>{mobile ? "Checkout" : "Proceed to checkout"}</button> */}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};
