import React, { useEffect, useContext, useState } from "react";
import useToggle from "../../hooks/useToggle";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { DataContext } from "../../common/DataContext";
import { useNavigate, useParams } from "react-router-dom";
import { ShoppingCartModal } from "../../components/common";
import {
  AddProductModal,
  ProductWidget,
  ImagePreviewModal,
  EditProductModal,
} from "../AdminProducts/adminProductComponents";
export const Shop = () => {
  const { fetchProducts, currentCategories, currentProducts, mobile } = useContext(DataContext);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [previewImages, setPreviewImages] = useState(null);
  const [selectedProductId,setSelectedProductId]=useState()
  const [selectedProductName,setSelectedProductName]=useState()
  const [selectedProductDesc,setSelectedProductDesc]=useState()
  const [selectedProductColor,setSelectedProductColor]=useState()
  const [selectedProductMaterial,setSelectedProductMarterial]=useState()
  const [selectedProductPrice,setSelectedProductPrice]=useState()
  const [selectedProductWeight,setSelectedProductWeight]=useState()
  const [selectedProductQuantity,setSelectedProductQuantity]=useState()
  const [selectedProductCategory,setSelectedProductCategory]=useState()
  const [selectedProductSubCategory,setSelectedProductSubCategory]=useState()
  const [selectedProductImages,setSelectedProductImages]=useState()
  const [previewModalOpen, togglePreviewModalOpen] = useToggle(false);
  const [productModalOpen, toggleProductModalOpen] = useToggle(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [selectedCategoryDescription, setSelectedCategoryDescription] = useState(null); // New state for category description
  const { categoryName } = useParams(); // Capture the category name from the URL

  // Fetch all products on initial load
  useEffect(() => {
    fetchProducts();
  }, []);

  // Match the category from the URL and set the selected category
  useEffect(() => {
    let currentUrl = window.location.href;
    let lastSegment = currentUrl.split("/").pop();
    let formattedSegment = lastSegment.replace(/-/g, " ");
    const matchedCategory = currentCategories?.find(
      (category) =>
        category.category_name.toLowerCase() === formattedSegment.toLowerCase()
    );
    if (matchedCategory) {
      setSelectedCategory(matchedCategory.category_id);
      setSelectedCategoryName(matchedCategory.category_name);
      setSelectedCategoryDescription(matchedCategory.category_desc); // Set category description
    } else {
      setSelectedCategory(null);
      setSelectedCategoryName("");
      setSelectedCategoryDescription(""); // Reset description if no match
    }
  }, [currentCategories, selectedCategory]);

  // Fetch products whenever the selected category changes
  useEffect(() => {
    if (currentCategories?.length) {
      if (categoryName) {
        const formattedCategory = categoryName.replace(/-/g, " ");
        const matchedCategory = currentCategories.find(
          (category) =>
            category.category_name.toLowerCase() === formattedCategory.toLowerCase()
        );

        if (matchedCategory) {
          setSelectedCategory(matchedCategory.category_id);
          setSelectedCategoryName(matchedCategory.category_name);
          setSelectedCategoryDescription(matchedCategory.category_desc); // Set category description
          fetchProducts(matchedCategory.category_id); // Fetch products for the selected category
        } else {
          setSelectedCategory(null);
          setSelectedCategoryName("");
          setSelectedCategoryDescription(""); // Reset description if no match
          fetchProducts(); // Fetch all products
        }
      } else {
        setSelectedCategory(null);
        setSelectedCategoryName("");
        setSelectedCategoryDescription(""); // Reset description if no match
        fetchProducts(); // Fetch all products
      }
    }
  }, [categoryName, currentCategories, selectedCategory]);

  // When the selectedCategory changes, fetch the products
  useEffect(() => {
    if (selectedCategory) {
      // Fetch products for the selected category
      fetchProducts(selectedCategory);
    }
  }, [selectedCategory]);





  const handleSetSelectedProductID=(
    product_id,
    product_name,
    product_desc,
    product_color,
    product_material,
    product_price,
    product_weight,
    product_quantity,
    product_category,
    images,

  )=>{
    console.log(
      product_id,
      product_name,
      product_desc,
      product_color,
      product_material,
      product_price,
      product_weight,
      product_quantity,
      product_category,
      images,

    )
    if(product_id===selectedProductId){
      setSelectedProductId(null)
      setSelectedProductName(null)
      setSelectedProductDesc(null)
      setSelectedProductColor(null)
      setSelectedProductMarterial(null)
      setSelectedProductPrice(null)
      setSelectedProductWeight(null)
      setSelectedProductQuantity(null)
      setSelectedProductCategory(null)
      setSelectedProductImages(null)
      toggleProductModalOpen(false)
    }else{
      setSelectedProductId(product_id)
      setSelectedProductName(product_name)
      setSelectedProductDesc(product_desc)
      setSelectedProductColor(product_color)
      setSelectedProductMarterial(product_material)
      setSelectedProductPrice(product_price)
      setSelectedProductWeight(product_weight)
      setSelectedProductQuantity(product_quantity)
      setSelectedProductCategory(product_category)
      setSelectedProductImages(images)
      toggleProductModalOpen(true)
    }

  }

  const handleTogglePreviewModalOpen = (e, images = null) => {
    e.stopPropagation();

    togglePreviewModalOpen();
    if (images !== null) {
      handleSetPreviewImages(images);
    }
  };

  const handleSetPreviewImages = (images) => {
    setPreviewImages(images);
  };
  // Helper function to get the subcategory name from the ID
  const getSubcategoryName = (categoryId, subcategoryId) => {
    const category = currentCategories?.find(
      (cat) => cat.category_id === categoryId
    );
    if (category) {
      const subcategory = category.subcategories?.find(
        (subcat) => subcat.id === subcategoryId
      );
      return subcategory ? subcategory.name : "Miscellaneous";
    }
    return "Miscellaneous";
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "rgb(78, 171, 218)",
      }}
    >
      {/* Fixed Header */}
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000, // Ensure header stays on top
        }}
      >
        <Header />
      </div>
      <ShoppingCartModal />
      <ImagePreviewModal
            modalHandler={handleTogglePreviewModalOpen}
            modalState={previewModalOpen}
            previewImages={previewImages}
            imageIndex={imageIndex}
            setImageIndex={setImageIndex}
          />
      {
       productModalOpen&&productModalOpen===true&&selectedProductId!==null?
        <>
          <ProductWidget
            product_id={selectedProductId}
            product_name={selectedProductName}
            product_desc={selectedProductDesc}
            product_color={selectedProductColor}
            product_material={selectedProductMaterial}
            product_price={selectedProductPrice}
            product_weight={selectedProductWeight}
            product_quantity={selectedProductQuantity}
            product_category={selectedProductCategory}
            images={selectedProductImages}
            imageIndex={imageIndex}
            productModalOpen={productModalOpen}
            toggleProductModalOpen={toggleProductModalOpen}
            setImageIndex={setImageIndex}
            // selectedProductID={selectedProductID}
            handleSetSelectedProductID={
              handleSetSelectedProductID
            }
            handleTogglePreviewModalOpen={
              handleTogglePreviewModalOpen
            }
            // modalState={previewModalOpen}
          />
        </>
        :
        <>
        </>
      }

      {/* Content section */}
      <div
        style={{
          flex: 1,
          overflowY: "auto",
          overflowX: "hidden", // Prevent horizontal scrolling
          marginTop: mobile?'30px':"40px", // Adjust to header height
          marginBottom: "60px", // Adjust to footer height
        }}
      >
        {/* SHOP banner */}
        <div
          style={{
            backgroundColor: "black",
            color: "white",
            textAlign: "center",
            paddingLeft:'1vw',
            paddingRight:'2vw',
            paddingTop: mobile?'1vh':"20px",
            paddingBottom: mobile?'1vh':"20px",
            marginTop: "10px",
            width: "100%",
          }}
        >
          {/* Display selected category or "Shop All" */}
          <h1 style={{ margin: "0", fontSize: mobile?'1.25rem':"2rem",textAlign:'center' }}>
            {selectedCategoryName ? `Shop ${selectedCategoryName}` : "SHOP ALL"}
          </h1>

          {/* Display category description or default message */}
          <p style={{ margin: "0", fontSize:mobile?'.8rem': "1.2rem" }}>
            {selectedCategoryDescription
              ? selectedCategoryDescription
              : "Shop all product categories"}
          </p>
        </div>

        {/* Conditionally render categories based on selection */}
        {selectedCategory ? (
          currentCategories?.map((category, index) => {
            // Filter products belonging to the selected category
            if (category.category_id === selectedCategory) {
              const categoryProducts = currentProducts?.filter(
                (product) => product.product_category === category.category_id
              );

              return (
                <div key={index} style={{ marginBottom: "30px" }}>
                  {/* Category label */}
                  <h2 style={{ textAlign: "center", marginLeft: "15px",marginTop:mobile?'0vh':'',marginBottom:mobile?'.25vh':''}}>
                    {category.category_name}
                  </h2>

                  {/* Check if category has products */}
                  {categoryProducts?.length > 0 ? (
                    Object.entries(
                      categoryProducts.reduce((acc, product) => {
                        const subcategoryName = getSubcategoryName(
                          product.product_category,
                          product.product_subcategory
                        );
                        if (!acc[subcategoryName]) {
                          acc[subcategoryName] = [];
                        }
                        acc[subcategoryName].push(product);
                        return acc;
                      }, {})
                    ).map(([subcategoryName, subcategoryProducts], subIndex) => (
                      <div key={subIndex} style={{ width:mobile?'99%':'',marginBottom: "20px", display:'flex',flexDirection:'column',alignItems:'center'}}>
                        {/* Subcategory label */}
                        <h3
                          style={{
                            textAlign: "center",
                            marginLeft: "20px",
                            fontSize: "1.2rem", // Smaller subcategory label
                            fontWeight: 600,
                            marginTop:mobile?'1vh':'',
                            marginBottom:mobile?'1vh':'',

                          }}
                        >
                          {subcategoryName}
                        </h3>

                        {/* Products grid for subcategory */}
                        <div
                          style={{
                            display: "grid",
                            width:mobile?'99%':'',
                            height:mobile?'55vh':'auto',
                            maxHeight:mobile?'66vh':'',
                            // backgroundColor:'green',
                            gridTemplateColumns:mobile? "repeat(4, 1fr)":'repeat(5, 1fr)',
                            overflowY:mobile?'scroll':'',
                            gap: mobile?"15px":'2vw', // Reduced gap to shrink the overall grid
                            justifyItems: "center",
                            alignItems:'center',
                            boxSizing: "border-box", // Ensures padding/margin doesn't overflow
                            paddingLeft:mobile?'20vw':'',
                            paddingRight:mobile?'20vw':'',
                            paddingBottom:mobile?'5vh':''

                          }}
                        >
                          {subcategoryProducts.map((product) => (
                            <div
                              key={product.product_id}
                              style={{
                                backgroundColor: "white",
                                padding: "2vw",
                                height:'auto',
                                maxHeight:mobile?'50vh':'',
                                borderRadius: "8px",
                                textAlign: "center",
                                width:mobile?'60vw':'15vw',

                                maxWidth: mobile?'60vw':"15vw", // Limit the size of product div
                                boxSizing: "border-box",
                              }}
                              onClick={(e)=>handleSetSelectedProductID(
                                product.product_id,
                                product.product_name,
                                product.product_desc,
                                product.product_color,
                                product.product_material,
                                product.product_price,
                                product.product_weight,
                                product.product_quantity,
                                product.product_category,
                                product.images,
                              )}
                            >
                              <img
                                src={product.images[0]?.thumbnail_url}
                                alt={product.product_name}
                                style={{
                                  width: "100%", // Adjust width to maintain aspect ratio
                                  height: "180px", // Fixed height for thumbnail images
                                  objectFit: "cover", // Ensure images fit within the div
                                  borderRadius: "8px",
                                }}
                              />
                              <h2 style={{ margin: "10px 0" }}>
                                {product.product_name}
                              </h2>
                              <p style={{ margin: "5px 0" }}>
                                ${product.product_price.toFixed(2)}
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  ) : (
                    // No products in this category, show "New products coming soon"
                    <div style={{ marginLeft: "20px" }}>
                      <p style={{ fontStyle: "italic", textAlign: "center" }}>
                        New products coming soon...
                      </p>
                    </div>
                  )}
                </div>
              );
            }
            return null; // Skip rendering for other categories
          })
        ) : (
          // Display all categories when none is selected
          currentCategories?.map((category, index) => {
            const categoryProducts = currentProducts?.filter(
              (product) => product.product_category === category.category_id
            );

            return (
              <div key={index} style={{ marginBottom: "30px" }}>
                {/* Category label */}
                <h2 style={{ textAlign: "center", marginLeft: "15px" }}>
                  {category.category_name}
                </h2>

                {/* Check if category has products */}
                {categoryProducts?.length > 0 ? (
                  Object.entries(
                    categoryProducts.reduce((acc, product) => {
                      const subcategoryName = getSubcategoryName(
                        product.product_category,
                        product.product_subcategory
                      );
                      if (!acc[subcategoryName]) {
                        acc[subcategoryName] = [];
                      }
                      acc[subcategoryName].push(product);
                      return acc;
                    }, {})
                  ).map(([subcategoryName, subcategoryProducts], subIndex) => (
                    <div key={subIndex} style={{ marginBottom: "20px" }}>
                      {/* Subcategory label */}
                      <h3
                        style={{
                          textAlign: "center",
                          marginLeft: "20px",
                          fontSize: "1.2rem", // Smaller subcategory label
                          fontWeight: 600,
                        }}
                      >
                        {subcategoryName}
                      </h3>

                      {/* Products grid for subcategory */}
                      <div
                        style={{
                          display: "grid",
                          width:mobile?'99%':'',
                          height:'auto',
                          // backgroundColor:'green',
                          gridTemplateColumns: "repeat(4, 1fr)",
                          overflowY:mobile?'scroll':'',
                          gap: "15px", // Reduced gap to shrink the overall grid
                          padding: "10px 15px", // Adjusted padding to avoid horizontal scroll
                          justifyItems: "center",
                          boxSizing: "border-box", // Ensures padding/margin doesn't overflow
                          paddingLeft:mobile?'20vw':'',
                          paddingRight:mobile?'20vw':''
                        }}
                      >
                        {subcategoryProducts.map((product) => (
                            <div
                            key={product.product_id}
                            style={{
                              backgroundColor: "white",
                              padding: "2vw",
                              height:'auto',
                              maxHeight:mobile?'50vh':'',
                              borderRadius: "8px",
                              textAlign: "center",
                              width:mobile?'60vw':'15vw',

                              maxWidth: mobile?'60vw':"15vw", // Limit the size of product div
                              boxSizing: "border-box",
                            }}
                            onClick={(e)=>handleSetSelectedProductID(
                              product.product_id,
                              product.product_name,
                              product.product_desc,
                              product.product_color,
                              product.product_material,
                              product.product_price,
                              product.product_weight,
                              product.product_quantity,
                              product.product_category,
                              product.images,
                            )}
                          >
                            <img
                              src={product.images[0]?.thumbnail_url}
                              alt={product.product_name}
                              style={{
                                width: "100%", // Adjust width to maintain aspect ratio
                                height: "180px", // Fixed height for thumbnail images
                                objectFit: "cover", // Ensure images fit within the div
                                borderRadius: "8px",
                              }}
                            />
                            <h2 style={{ margin: "10px 0" }}>
                              {product.product_name}
                            </h2>
                            <p style={{ margin: "5px 0" }}>
                              ${product.product_price.toFixed(2)}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))

                ) : (
                  // No products in this category, show "New products coming soon"
                  <div style={{ marginLeft: "20px" }}>
                    <p style={{ fontStyle: "italic", textAlign: "center" }}>
                      New products coming soon...
                    </p>
                  </div>
                )}
              </div>
            );
          })
        )}
      </div>

      {/* Fixed Footer */}
      <div
        style={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          zIndex: 1000, // Ensure footer stays on top
        }}
      >
        <Footer />
      </div>
    </div>
  );
};
