import React, { useState, useContext, useEffect } from "react";
import products_icon from "../../static/products_icon.png";
import categories_icon from "../../static/categories_icon.png";
import orders_icon from "../../static/orders_icon.png";
import settings_icon from "../../static/settings_icon.png";
import logout_icon from "../../static/logout_icon.png";
import upIcon from "../../static/images/upIcon.png";
import downIcon from "../../static/images/downIcon.png";
import leftIcon from "../../static/images/leftIcon.png";
import rightIcon from "../../static/images/rightIcon.png";
import closeIcon from "../../static/images/close_icon.png";

import { DataContext } from "../../common/DataContext";
import { StyledButton } from "../../components/common";

export const EditProductModal = (props) => {
  const {
    newProductImages,
    setNewProductImages,
    currentImageURLs,
    setCurrentImageURLs,
    handleSetNewProductImages,
    handleAddNewProductImagePreviews,
    selectedProductImages,
    setSelectedProductImages,
  } = useContext(DataContext);

  //console.log(newProductImages)
  useEffect(() => {
    console.log(selectedProductImages);
  }, [selectedProductImages]);

  const moveUpImage = (index) => {
    if (index > 0) {
      const updatedPreviews = [...selectedProductImages];
      [updatedPreviews[index - 1], updatedPreviews[index]] = [
        updatedPreviews[index],
        updatedPreviews[index - 1],
      ];
      console.log(updatedPreviews);
      setSelectedProductImages(updatedPreviews);
    }
  };

  // Function to move an image down in the order
  const moveDownImage = (index) => {
    if (index < selectedProductImages.length - 1) {
      const updatedPreviews = [...selectedProductImages];
      [updatedPreviews[index], updatedPreviews[index + 1]] = [
        updatedPreviews[index + 1],
        updatedPreviews[index],
      ];
      console.log(updatedPreviews);
      setSelectedProductImages(updatedPreviews);
    }
  };
  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <>
          <div
            style={{
              position: "fixed",
              left: "40vw",
              top: "12vh",
              width: "40vw",
              height: "85vh",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              // justifyContent: "top",
              boxShadow: "1px 1px 6px 2px black",
              zIndex: "999",
            }}
          >
            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'red',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "top",
                marginBottom: "1vh",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: "2%",
                  width: "2vh",
                  height: "2vh",
                  // backgroundColor: "black",
                }}
                onClick={() => props.modal_handler()}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>

              <h2>{props.title}</h2>
            </div>

            <div
              style={{
                width: "100%",
                height: "auto",
                // backgroundColor:'lightsalmon',
                display: "flex",
                flexDirection: "column",
                alignItems: "center",

                marginTop: "1vh",
                overflowX: "scroll",
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  // backgroundColor:'grey',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                  // marginTop:'5vh'
                }}
              >
                <h2
                  style={{
                    marginLeft: "1vw",
                  }}
                >
                  {"Product Name:"}
                </h2>
                <input
                  type="text"
                  value={props.newProductName}
                  onChange={(e) => props.handleSetNewProductName(e)}
                  style={{
                    height: "50%",
                    width: "65%",
                    marginLeft: "2vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  // backgroundColor:'grey',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "24.5%",
                  }}
                >
                  {"Product Description:"}
                </h2>
                <input
                  type="text"
                  value={props.newProductDesc}
                  onChange={(e) => props.handleSetNewProductDesc(e)}
                  style={{
                    height: "50%",
                    width: "65%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  // backgroundColor:'grey',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "24.5%",
                  }}
                >
                  {"Material:"}
                </h2>
                <input
                  type="text"
                  value={props.newProductMaterial}
                  onChange={(e) => props.handleSetNewProductMaterial(e)}
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />

                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "21%",
                  }}
                >
                  {"SKU:"}
                </h2>
                <input
                  value={props.newProductSKU}
                  onChange={(e) => props.handleSetNewProductSKU(e)}
                  type="text"
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  // backgroundColor:'grey',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "24.5%",
                  }}
                >
                  {"Color:"}
                </h2>
                <input
                  type="text"
                  value={props.newProductColor}
                  onChange={(e) => props.handleSetNewProductColor(e)}
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />
                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "21%",
                  }}
                >
                  {"Dimensions:"}
                </h2>
                <input
                  type="text"
                  value={props.newProductDimensions}
                  placeholder="ex: 2x2x4 in"
                  onChange={(e) => props.handleSetNewProductDimensions(e)}
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  // backgroundColor:'grey',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "24.5%",
                  }}
                >
                  {"Price:"}
                </h2>

                <input
                  type="number"
                  step={0.01}
                  value={props.newProductPrice}
                  onChange={(e) => props.handleSetNewProductPrice(e)}
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />

                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "21%",
                  }}
                >
                  {"Weight in grams:"}
                </h2>

                <input
                  type="number"
                  value={props.newProductWeight}
                  onChange={(e) => props.handleSetNewProductWeight(e)}
                  step={0.01}
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  height: "10vh",
                  // backgroundColor:'grey',
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "24.5%",
                  }}
                >
                  {"Quantity:"}
                </h2>

                <input
                  type="number"
                  value={props.newProductQuantity}
                  onChange={(e) => props.handleSetNewProductQuantity(e)}
                  step={1}
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />

                <h2
                  style={{
                    marginLeft: "1vw",
                    width: "21%",
                  }}
                >
                  {"Category:"}
                </h2>

                <input
                  type="text"
                  value={props.newProductCategory}
                  onChange={(e) => props.handleSetNewProductCategory(e)}
                  step={0.01}
                  style={{
                    height: "50%",
                    width: "18%",
                    marginLeft: "1vw",
                    boxShadow: "1px 1px 6px 2px grey",
                  }}
                />
              </div>

              <div
                style={{
                  height: "48vh",
                  width: "100%",
                  // backgroundColor:'lightpink',
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "top",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "98%",
                    height: "auto",
                    // backgroundColor: "grey",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "1px 1px 6px 2px grey",
                    overflowX: "scroll",
                    marginBottom: "1vh",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "4vh",
                      // backgroundColor: "grey",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <h2
                      style={{
                        marginLeft: "1vw",
                        width: "24.5%",
                      }}
                    >
                      {"Current Images:"}
                    </h2>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "13vh",
                      // backgroundColor: "lightgreen",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    {selectedProductImages &&
                      selectedProductImages.slice().map((row, rowindex) => {
                        const { url, thumbnail_url, index } = row;
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "1vw",
                              width: "5rem",
                              height: "6rem",
                              marginLeft: "1vw",
                            }}
                          >
                            <img
                              style={{
                                width: "5rem",
                                height: "6rem",
                                // margin:'auto'
                              }}
                              src={thumbnail_url}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "3vh",
                                width: "100%",
                                // backgroundColor: "grey",
                              }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "1vw",
                                  height: "auto",
                                  // backgroundColor: "lightgrey",

                                  // marginBottom:'1vh'
                                }}
                              >
                                {rowindex}
                              </div>

                              <img
                                src={leftIcon}
                                style={{
                                  width: ".70vw",
                                  marginRight: "1vh",
                                  marginLeft: ".25vw",
                                }}
                                onClick={() => moveUpImage(rowindex)}
                              />
                              <img
                                src={rightIcon}
                                style={{ width: ".70vw", marginRight: ".25vw" }}
                                onClick={() => moveDownImage(rowindex)}
                              />

                              <img
                                style={{
                                  width: "auto",
                                  height: "2vh",
                                  margin: "auto",
                                }}
                                src={closeIcon}
                                onClick={() =>
                                  props.handleDeleteSelectedProductImage(index)
                                }
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>

                <div
                  style={{
                    width: "98%",
                    height: "auto",
                    // backgroundColor: "grey",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    boxShadow: "1px 1px 6px 2px grey",
                    overflowX: "scroll",
                    marginBottom: "1vh",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "4vh",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "left",
                    }}
                  >
                    <h2
                      style={{
                        marginLeft: "1vw",
                        width: "30%",
                      }}
                    >
                      {"Add Images:"}
                    </h2>

                    <input
                      type="file"
                      accept=".jpg, .png, .jpeg,"
                      onChange={(e) => handleSetNewProductImages(e)}
                      multiple
                    />
                  </div>

                  <div
                    style={{
                      width: "100%",
                      height: "12vh",
                      // backgroundColor: "lightgrey",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "left",
                    }}
                  >
                    {currentImageURLs &&
                      currentImageURLs.map((row, index) => {
                        console.log(currentImageURLs);
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: "1vw",
                              width: "5rem",
                              height: "6rem",
                              marginLeft: "1vw",
                              marginTop: "1vh",
                            }}
                          >
                            <img
                              style={{
                                width: "5rem",
                                height: "6rem",
                                // margin:'auto'
                              }}
                              src={row}
                            />
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                height: "3vh",
                                width: "100%",
                                // backgroundColor: "grey",
                              }}
                            >
                              {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "1vw",
                            height: "auto",
                            backgroundColor: "lightgrey",
                            marginLeft: ".5vw",
                            marginRight: ".5vw",
                            // marginBottom:'1vh'
                          }}
                        >
                          {index}
                        </div> */}
                              {/* 
                        <img
                          src={leftIcon}
                          style={{ width: ".70vw", marginRight: ".5vw" }}
                          onClick={() => moveUpImage(index)}
                        />
                        <img
                          src={rightIcon}
                          style={{ width: ".70vw" }}
                          onClick={() => moveDownImage(index)}
                        /> */}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2vh",
                marginTop: "2vh",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={() => props.modal_handler()}
              />
              <StyledButton
                buttonText={"Edit"}
                buttonAction={() => props.handleEditProduct()}
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const AddProductModal = (props) => {
  const {
    newProductImages,
    setNewProductImages,
    currentImageURLs,
    setCurrentImageURLs,
  } = useContext(DataContext);

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedSubcategory, setSelectedSubcategory] = useState();

  const [subcategories, setSubcategories] = useState([]);

  const handleSetSelectedCategory = (e) => {
    let categoryId = parseInt(e.target.value);
    console.log(props.currentCategories, categoryId);
    setSelectedCategory(categoryId);
    let category =
      props.currentCategories &&
      props.currentCategories.find((cat) => cat.category_id === categoryId);
    if (category) {
      console.log(category.subcategories);
      setSubcategories(category.subcategories || []);
    } else {
      setSubcategories([]);
    }
  };

  const handleSetSelectedSubcategory = (e) => {
    let subcategoryId = parseInt(e.target.value);

    setSelectedSubcategory(subcategoryId);
  };
  const moveUpImage = (index) => {
    if (index > 0) {
      const updatedPreviews = [...newProductImages];
      [updatedPreviews[index - 1], updatedPreviews[index]] = [
        updatedPreviews[index],
        updatedPreviews[index - 1],
      ];
      setNewProductImages(updatedPreviews);
      const previews = [];
      for (let i = 0; i < updatedPreviews.length; i++) {
        const file = updatedPreviews[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const previewUrl = e.target.result;
          previews.push(previewUrl);
          if (previews.length === updatedPreviews.length) {
            setCurrentImageURLs(previews);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };

  // Function to move an image down in the order
  const moveDownImage = (index) => {
    if (index < newProductImages.length - 1) {
      const updatedPreviews = [...newProductImages];
      [updatedPreviews[index], updatedPreviews[index + 1]] = [
        updatedPreviews[index + 1],
        updatedPreviews[index],
      ];
      setNewProductImages(updatedPreviews);
      const previews = [];
      for (let i = 0; i < updatedPreviews.length; i++) {
        const file = updatedPreviews[i];
        const reader = new FileReader();
        reader.onload = (e) => {
          const previewUrl = e.target.result;
          previews.push(previewUrl);
          if (previews.length === updatedPreviews.length) {
            setCurrentImageURLs(previews);
          }
        };
        reader.readAsDataURL(file);
      }
    }
  };
  return (
    <>
      {props.modal_open && props.modal_open === true ? (
        <>
          <div
            style={{
              position: "fixed",
              left: "40vw",
              top: "12vh",
              width: "40vw",
              height: "85vh",
              backgroundColor: "ghostwhite",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "top",
              boxShadow: "1px 1px 6px 2px black",
              zIndex: "999",
            }}
          >
            <div
              style={{
                position: "absolute",
                right: "2%",
                top: "2%",
                width: "2vh",
                height: "2vh",
                // backgroundColor: "black",
              }}
              onClick={() => props.modal_handler()}
            >
              <img
                style={{
                  width: "auto",
                  height: "2vh",
                  margin: "auto",
                }}
                src={closeIcon}
              />
            </div>
            <h2>{props.title}</h2>
            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                }}
              >
                {"Product Name:"}
              </h2>
              <input
                type="text"
                value={props.newProductName}
                onChange={(e) => props.handleSetNewProductName(e)}
                style={{
                  height: "50%",
                  width: "65%",
                  marginLeft: "2vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Product Description:"}
              </h2>
              <input
                type="text"
                value={props.newProductDesc}
                onChange={(e) => props.handleSetNewProductDesc(e)}
                style={{
                  height: "50%",
                  width: "65%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Material:"}
              </h2>
              <input
                type="text"
                value={props.newProductMaterial}
                onChange={(e) => props.handleSetNewProductMaterial(e)}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />

              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Color:"}
              </h2>
              <input
                type="text"
                value={props.newProductColor}
                onChange={(e) => props.handleSetNewProductColor(e)}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            {/* 
//HERE */}
            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                paddingRight: "2vw",
                paddingLeft: "2vw",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "auto",
                }}
              >
                {"Length:"}
              </h2>
              <input
                type="number"
                step={0.25}
                value={props.newProductLength}
                onChange={(e) => props.handleSetNewProductLength(e)}
                style={{
                  height: "50%",
                  width: "5vw",
                  marginLeft: "2vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "Auto",
                }}
              >
                {"Width:"}
              </h2>
              <input
                type="number"
                step={0.25}
                value={props.newProductWidth}
                // placeholder="ex: 2x2x4 in"
                onChange={(e) => props.handleSetNewProductWidth(e)}
                style={{
                  height: "50%",
                  width: "5vw",
                  marginLeft: "2vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "Auto",
                }}
              >
                {"Height:"}
              </h2>
              <input
                type="number"
                step={0.25}
                value={props.newProductHeight}
                // placeholder="ex: 2x2x4 in"
                onChange={(e) => props.handleSetNewProductHeight(e)}
                style={{
                  height: "50%",
                  width: "5vw",
                  marginLeft: "2vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Price:"}
              </h2>

              <input
                type="number"
                step={0.01}
                value={props.newProductPrice}
                onChange={(e) => props.handleSetNewProductPrice(e)}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />

              <h2
                style={{
                  marginLeft: "1vw",
                  width: "21%",
                }}
              >
                {"Weight in grams:"}
              </h2>

              <input
                type="number"
                value={props.newProductWeight}
                onChange={(e) => props.handleSetNewProductWeight(e)}
                step={0.01}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Quantity:"}
              </h2>

              <input
                type="number"
                value={props.newProductQuantity}
                onChange={(e) => props.handleSetNewProductQuantity(e)}
                step={1}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "21%",
                }}
              >
                {"Category:"}
              </h2>
              {/* 
              <input
                type="text"
                value={props.newProductCategory}
                onChange={(e) => props.handleSetNewProductCategory(e)}
                step={0.01}
                style={{
                  height: "50%",
                  width: "18%",
                  marginLeft: "1vw",
                  boxShadow: "1px 1px 6px 2px grey",
                }}
              /> */}

              <select
                style={{
                  marginRight: "4vw",
                  width: "21%",
                }}
                onChange={(e) => handleSetSelectedCategory(e)}
                id="categories"
              >
                <option value="">Select a category</option>
                {props.currentCategories.map((category) => (
                  <option
                    key={category.category_id}
                    value={category.category_id}
                  >
                    {category.category_name}
                  </option>
                ))}
              </select>
              <h2
                style={{
                  marginRight: "1vw",
                }}
              >
                {"Subcategory:"}
              </h2>
              <select
                onChange={(e) => handleSetSelectedSubcategory(e)}
                id="subcategories"
                disabled={!selectedCategory}
              >
                <option value="">Select a subcategory</option>
                {subcategories &&
                  subcategories.map((subcategory) => (
                    <option key={subcategory.id} value={subcategory.id}>
                      {subcategory.name}
                    </option>
                  ))}
              </select>
            </div>

            <div
              style={{
                width: "100%",
                height: "4vh",
                backgroundColor: "grey",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
              }}
            >
              <h2
                style={{
                  marginLeft: "1vw",
                  width: "24.5%",
                }}
              >
                {"Images:"}
              </h2>

              <input
                type="file"
                accept=".jpg, .png, .jpeg,"
                onChange={(e) => props.handleSetNewProductImages(e)}
                multiple
                // value={props.newProductQuantity}
                // onChange={(e)=>props.handleSetNewProductQuantity(e)}
                // step={1}
                // style={{
                //   height:'50%',
                //   width:'18%',
                //   marginLeft:'1vw',
                //   boxShadow:"1px 1px 6px 2px grey",
                // }}
              />
            </div>

            <div
              style={{
                width: "100%",
                height: "28vh",
                backgroundColor: "lightgrey",
                display: "flex",
                flexDirection: "row",
                justifyContent: "left",
                marginBottom: "1vh",
                overflowX: "scroll",
                overflowY: "scroll",
              }}
            >
              {/* <img src={currentImageURLs[0]}/> */}
              {currentImageURLs &&
                currentImageURLs.map((row, index) => {
                  console.log(currentImageURLs);
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginRight: "1vw",
                        width: "5rem",
                        height: "6rem",
                        marginLeft: "1vw",
                      }}
                    >
                      <img
                        style={{
                          width: "5rem",
                          height: "6rem",
                          // margin:'auto'
                        }}
                        src={row}
                      />
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          height: "3vh",
                          width: "100%",
                          backgroundColor: "grey",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "center",
                            width: "1vw",
                            height: "auto",
                            backgroundColor: "lightgrey",
                            marginLeft: ".5vw",
                            marginRight: ".5vw",
                            // marginBottom:'1vh'
                          }}
                        >
                          {index}
                        </div>

                        <img
                          src={leftIcon}
                          style={{ width: ".70vw", marginRight: ".5vw" }}
                          onClick={() => moveUpImage(index)}
                        />
                        <img
                          src={rightIcon}
                          style={{ width: ".70vw" }}
                          onClick={() => moveDownImage(index)}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>

            <div
              style={{
                width: "100%",
                height: "10vh",
                // backgroundColor:'grey',
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2vh",
              }}
            >
              <StyledButton
                buttonText={"Cancel"}
                buttonAction={() => props.handleCancelProduct()}
              />
              <StyledButton
                buttonText={"Create"}
                buttonAction={() =>
                  props.handleCreateProduct(
                    selectedCategory,
                    selectedSubcategory,
                  )
                }
              />
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export const ProductWidget = (props) => {
  console.log(props)
  const {
    setTempIndex,
    shoppingCart,
    setShoppingCart,
    handleAddItemToCart,
    mobile,
  } = useContext(DataContext);

  useEffect(() => {
    console.log(props);
  }, [props]);

  const [addQuantity, setAddQuantity] = useState(0);

  const handleSetAddQuantity = (e, op = null) => {
    if (!op) {
      setAddQuantity(e.target.value);
    } else {
      let tempQuant = addQuantity;
      if (op === "+") {
        tempQuant += 1;
      } else if (op === "-") {
        tempQuant -= 1;
      }
      setAddQuantity(tempQuant);
    }
  };

  const cycleUpImage = (e) => {
    e.stopPropagation();

    if (props.imageIndex < props.images.length - 1) {
      props.setImageIndex((count) => count + 1);
    } else {
      props.setImageIndex(0);
    }

    if (props.modalState === true) {
      props.handleTogglePreviewModalOpen(e);
    }
  };

  // Function to move an image down in the order
  const cycleDownImage = (e) => {
    e.stopPropagation();
    if (props.modalState === true) {
      props.handleTogglePreviewModalOpen(e);
    }

    if (props.imageIndex > 0) {
      props.setImageIndex((count) => count - 1);
    } else {
      props.setImageIndex(props.images.length - 1);
    }
  };

  const handleAddItem = (
    e,
    product_id,
    product_name,
    images,
    product_price,
    addQuantity,
    length,
    width,
    height,
  ) => {
    handleAddItemToCart(
      e,
      product_id,
      product_name,
      images,
      product_price,
      addQuantity,
      length,
      width,
      height,
    );
    setAddQuantity(0);
  };

  return (
    <div
      style={{
        position:'fixed',
        top:'18vh',
        left:'7vw',
        width: mobile ? "80%" : "20vw",
        maxWidth: mobile ? "100%" : "25vw",
        
        height: mobile ? "auto" : "auto",
        minHeight:'50vh',
        display: "flex",
        flexDirection: "column",
        marginBottom: "3vw",
        padding: mobile ? "3vw" : "1.5vw",
        backgroundColor:
          props.selectedProductID === props.product_id ? "#87CEFA" : "white",
        boxShadow: "1px 1px 6px 2px black",
        alignItems: "center",
      }}
      value={props.product_id}
      key={props.product_id}
      // onClick={(e) =>
      //   props.mode !== "store"
      //     ? props.handleSetSelectedProductID(
      //         e,
      //         props.product_id,
      //         props.product_name,
      //         props.product_desc,
      //         props.product_color,
      //         props.product_material,
      //         props.product_price,
      //         props.product_weight,
      //         props.product_quantity,
      //         props.product_category,
      //         props.images,
      //       )
      //     : null
      // }
    >
                    <div
                style={{
                  position: "absolute",
                  right: "2%",
                  top: mobile ? ".5vh" : "2%",
                  width: "2vh",
                  height: "2vh",
                  // backgroundColor: "black",
                }}
                onClick={(e) => props.toggleProductModalOpen(e)}
              >
                <img
                  style={{
                    width: "auto",
                    height: "2vh",
                    margin: "auto",
                  }}
                  src={closeIcon}
                />
              </div>
      <div
        style={{
          width: "18vw",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          // backgroundColor:'green'
        }}
      >
        <img
          src={leftIcon}
          style={{ width: ".70rem", marginRight: ".5rem", marginLeft: ".5rem" }}
          onClick={(e) => cycleDownImage(e)}
        />

        <img
          style={{
            height: mobile ? "6rem" : "10rem",
            width: "auto",

            boxShadow: props.images[props.imageIndex]
              ? "1px 1px 6px 2px grey"
              : null,
          }}
          src={
            props.images[props.imageIndex] && props.images[props.imageIndex]
              ? props.images[props.imageIndex].thumbnail_url
              : null
          }
          onClick={(e) => props.handleTogglePreviewModalOpen(e, props.images)}
        />

        <img
          src={rightIcon}
          style={{ width: ".70rem", marginLeft: ".5rem", marginRight: ".5rem" }}
          onClick={(e) => cycleUpImage(e)}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          // backgroundColor:'red',
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "1vh",
          marginTop: "1vh",
        }}
      >
        {/* <strong>{"Name:"}</strong> */}

        <label
          style={{
            fontSize: mobile ? "1rem" : "1.25rem",
            // backgroundColor:'green',
            color: "black",
            fontWeight: mobile ? 500 : 700,
            textAlign: "center",
            // marginBottom: "2vh",
          }}
        >
          <strong>{props.product_name}</strong>
        </label>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          alignItems: "center",
          marginBottom: "1vh",
          textAlign: "center",
        }}
      >
        {!mobile ? (
          <>
            <label
              style={{
                fontSize: mobile ? "1rem" : "1.25rem",
                color: "black",
                fontWeight: 700,
                textAlign: "center",
              }}
            >
              <strong>{"Description:"}</strong>
            </label>
          </>
        ) : null}

        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.25rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {props.product_desc}
        </label>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          alignItems: "center",
          marginBottom: "1vh",
        }}
      >
        <label
          style={{
            fontSize: mobile ? ".9rem" : "1.25rem",
            color: "black",
            fontWeight: 700,
            textAlign: "center",
          }}
        >
          <strong>{"Material:"}</strong>
        </label>
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1.25rem",
            color: "black",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          {props.product_material}
        </label>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "auto",
          width: "100%",
          alignItems: "center",
          // backgroundColor:'red',
          justifyContent: "center",
          marginBottom: "1vh",
          gap:'1vw'
        }}
      >
        <label
          style={{
            fontSize: mobile ? ".8rem" : "1rem",

          }}
        >
          <strong>{`Length: `}</strong>
        </label>

        <label
          style={{
            fontSize: mobile ? ".8rem" : "1rem",

          }}
        >
          {props.product_length}
        </label>

        <label
          style={{

            fontSize: mobile ? ".8rem" : "1rem",
          }}
        >
          <strong>{`Width: `}</strong>
        </label>

        <label
          style={{
            fontSize: mobile ? ".8rem" : "1rem",

          }}
        >
          {props.product_width}
        </label>

        <label
          style={{

            fontSize: mobile ? ".8rem" : "1rem",
          }}
        >
          <strong>{`Height: `}</strong>
        </label>

        <label
          style={{
            fontSize: mobile ? ".8rem" : "1rem",

          }}
        >
          {props.product_height}
        </label>
      </div>

      {/* 
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          justifyContent: "center",
          marginBottom: "1vh",
        }}
      >


        <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "auto",
          width: "100%",
          justifyContent: "center",
          marginBottom: "1vh",
        }}
      >
          
        <label
          style={{
            marginRight: ".5vw",
            fontSize: mobile?'.8rem':"1.25rem",
          }}
        >
          <strong>{`Weight:`}</strong>
        </label>

        <label
          style={{
            fontSize: mobile?'.8rem':"1.25rem",
            marginRight: "1vw",
          }}
        >
           {`${props.product_weight} grams`}
        </label>
          
         
        </div>
      </div> */}

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "auto",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "2vh",
        }}
      >
        <div style={{ marginRight: "1vw", marginBottom: "1vh" }}>
          <strong>{`In Stock: `}</strong>
          {props.product_quantity}
        </div>
        <div style={{ marginRight: "1vw" }}>
          <label
            style={{
              marginRight: ".5vw",
              fontSize: mobile ? "1.25rem" : "1.25rem",
            }}
          >
            <strong>{`Price: $`}</strong>
          </label>

          <label
            style={{
              fontSize: mobile ? "1.25rem" : "1.25rem",
              marginRight: "1vw",
            }}
          >
            {props.product_price}
          </label>
        </div>
      </div>

      {props.mode === "store" ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "auto",
              alignItems: "center",
              justifyContent: "center",
              gap: "2vw",
              // backgroundColor:'grey'
            }}
          >
            {mobile ? (
              <>
                <label
                  style={{
                    // backgroundColor:'red',
                    height: "auto",
                    fontSize: mobile ? "1.3rem" : "1.25rem",
                    textAlign: "center",
                    padding: "0",
                  }}
                >
                  Quantity: {addQuantity}
                </label>
                <button onClick={(e) => handleSetAddQuantity(e, "-")}>-</button>

                <button onClick={(e) => handleSetAddQuantity(e, "+")}>+</button>
              </>
            ) : (
              <>
                <input
                  value={addQuantity}
                  onChange={(e) => handleSetAddQuantity(e)}
                  type="number"
                  max={props.product_quantity}
                  style={{
                    width: "8vw",
                    marginRight: "2vw",
                  }}
                ></input>
              </>
            )}

            <button
              onClick={(e) =>
                handleAddItem(
                  e,
                  props.product_id,
                  props.product_name,
                  props.images,
                  props.product_price,
                  addQuantity,
                  props.product_length,
                  props.product_width,
                  props.product_height,
                )
              }
            >
              {"Add to Cart"}
            </button>
          </div>
        </>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "auto",
              width: "100%",
              alignItems: "center",
              marginBottom: "0vh",
            }}
          >
            <strong>{`Images:`}</strong>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                height: "6rem",
                alignItems: "center",
                justifyContent: "left",
                marginTop: "1vh",
                overflowX: "scroll",

                // backgroundColor:'grey'
              }}
            >
              {props.images&&props.images.slice().map((row) => {
                const { url, thumbnail_url } = row;
                return (
                  <>
                    <img
                      style={{
                        width: "5rem",
                        height: "auto",
                        margin: "auto",
                      }}
                      src={thumbnail_url}
                    />
                  </>
                );
              }
            
              )

            }
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const ImagePreviewModal = (props) => {
  const { tempIndex, setTempIndex, mobile } = useContext(DataContext);

  return (
    <>
      {props.modalState === true ? (
        <>
          <div
            style={{
              position: "absolute",
              center: "50%",
              top: mobile ? "18vh" : "6vw",
              left:mobile?'9vw':'',
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "auto",
              height: mobile ? "auto" : "85vh",
              backgroundColor: "ghostwhite",
              padding:mobile?'1vw':'',
              zIndex: "9999",
              /* The above code is setting the CSS properties `paddingLeft` and `paddingRight` to `2vw` for an
element. */
              // paddingLeft:'2vw',
              // paddingRight:'2vw',
              boxShadow: "1px 1px 6px 2px black",
            }}
            onClick={(e) => props.modalHandler(e)}
          >
            {/* <div
              style={{
                position: "absolute",
                top: "1vh",
                right: ".5vw",
                width: "auto",
              }}
              onClick={(e) => props.modalHandler(e)}
            >
              <img
                style={{
                  width: "auto",
                  height: "2vh",
                  margin: "auto",
                }}
                src={closeIcon}
              />
            </div> */}

            <div
              style={{
                width: "auto",
                height: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor:'green'
              }}
            >
              <img
                style={{
                  height: "90%",
                  width: "auto",
                  maxHeight: mobile ? "60vw" : "",
                  maxWidth: mobile ? "90vw" : "",
                  margin: "auto",
                  marginRight: "1vw",
                  marginLeft: "1vw",
                }}
                src={props.previewImages[props.imageIndex].url}
                // onClick={(e)=>props.modalHandler(e,props.previewImages[imageIndex].url,imageIndex)}
              />
            </div>

            {/* 

          <img 
          style={{
            width:'auto',
            height:'90%',
            margin:'auto',
            }}src={props.previewURL}
          /> */}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

// export const ControlButtonsWidget=(props)=>{
//     return(
//         <>
//               <div
//                 style={{
//                   width:'20%',
//                   display:'flex',
//                   flexDirection:'column',
//                   width:'22%',
//                   height:'80%',
//                   backgroundColor:'white',
//                   marginLeft:'2vw',

//                 }}
//               >
//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'25%',
//                     // backgroundColor:'green',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'

//                   }}
//                 >
//                   <h1>
//                     {<strong>Orders:</strong>}
//                   </h1>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightblue',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'

//                   }}
//                 >
//                   <h2>
//                     {<strong>New:</strong>}
//                   </h2>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightcoral',
//                     alignItems:'center',
//                     marginBottom:'1vh',
//                     paddingLeft:'1vw'
//                   }}
//                 >
//                   <h2>
//                     {<strong>Completed:</strong>}
//                   </h2>
//                 </div>

//                 <div
//                   style={{

//                     display:'flex',
//                     flexDirection:'row',
//                     width:'100%',
//                     height:'20%',
//                     // backgroundColor:'lightyellow',
//                     alignItems:'center',
//                     paddingLeft:'1vw'
//                   }}
//                 >
//                   <h2>
//                     {<strong>Refunded:</strong>}
//                   </h2>
//                 </div>

//               </div>
//         </>
//     )
// }
